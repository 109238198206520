<template>
  <!-- LawBot SideBar -->
  <div>
    <div class="sidebar">
        <div class="top-icon">
        <span class="material-symbols-rounded">smart_toy</span>
        </div>
        <button class="collapse-btn" @click="toggleSidebar">
        <span class="material-symbols-rounded">keyboard_double_arrow_left</span>
        </button>
    </div>
    
    <!-- Normal Mode -->
    <transition name="slide" v-if="!expertmode">
        <div v-if="isSidebarVisible" class="normal">
        <div class="d-flex justify-content-between top_bar">
            <div class="d-flex align-items-center lb_left">
              <span class="material-symbols-rounded">smart_toy</span>
              <p>LawBot</p>
            </div>
            <div>
            </div>
          </div>

        <div class="main-content">
          <!-- left side -->
            <div class="left_side">
            <div class="d-flex justify-content-between ls">
              <p>Recent Conversations</p>
              <span class="material-symbols-rounded">border_color</span>
            </div>
            <div v-if="chatMessages.length === 0" class="chat-placeholder">
              <img :src="fileImg" alt="" style="width: 80px; height: 80px;">
              <p>Chat history will show up here once you start a conversation.</p>
            </div>
              <div v-else class="recent-conversations">
              <div v-for="(message, idx) in filteredMessages" :key="idx" class="message-item">
                <div class="message-header">
                  <p>{{ truncateText(message.text, 27) }}</p>
                  <span class="material-symbols-rounded delete-icon"  @click="$bvModal.show(`alert-modal-${idx}`)">delete</span>
                    <b-modal
                      :id="`alert-modal-${idx}`"
                      custom-class="delete-modal"
                      hide-header
                      hide-footer
                      centered
                    >
                      <div class="delete-prompt">
                        <p><b>Are you sure you want to delete this conversation?</b><br></p>
                        <p class="bg">This action cannot be undone.</p>
                      </div>
                      <div class="delete-prompt-options">
                        <button class="btn-outlined" @click="$bvModal.hide(`alert-modal-${idx}`)">Cancel</button>
                        <button class="btn-danger" @click="deleteMessage(idx)">Delete</button>
                      </div>
                    </b-modal>
                </div>
                <div class="message-subheader">
                  <p>{{ "From Legal AI Copilot" }}</p>
                </div>
              </div>
            </div>
            </div>
            <button class="collapse-btns" @click="toggleSidebar">
              <span class="material-symbols-rounded">keyboard_double_arrow_right</span>
            </button>
            <!-- right side -->
            <div class="content-area">
              <div class="no-questions" v-if="!chatMessages.length">
                  <img :src="chat" alt="" style="width:180px;height:180px;">
                  <p>No questions asked yet!</p>
                  <p>Start by selecting a sample question to begin.</p>
              </div>
              <div class="card border-0 card_question">
              <div class="chat__box" id="messages" ref="messages1">
                <!-- Incoming Chat box -->
                <div
                  v-for="(message, idx) in chatMessages"
                  :key="idx"
                  :class="message.class_1"
                >

                <div class="d-flex align-items-start">
                  <span v-if="idx % 2 !== 0" class="material-symbols-rounded st_icon">smart_toy</span>
                  <div :class="message.class_1">
                    <div :class="message.class_3">
                      <span v-html="
                        message.text
                          .replace(/\\n*/g, '<br>')
                          .replace(/\\n\\n*/g, '<br>')
                          .replace(/\\n\\n/g, '<br>')
                          .replace(/\\n/g, '<br>')
                          .replace(/\n/g, '<br>')
                          .replace(/\n\n*/g, '<br>')
                          .replace('*', '')
                          .replace(/\\/g, '<br>')
                      "></span>
                    </div>
                  </div>
                </div>
              </div>
                <!-- Outgoing chat box -->
                <div v-if="loadAnswer" class="chat__incoming">
                  <div class="chat__bubble__wrapper-incoming">
                    <div
                      class="chat__bubble-incoming animated-background-incoming"
                    >
                      <div
                        style="transform: scale(0.7)"
                        class="circles-to-rhombuses-spinner"
                      >
                        <div class="circle"></div>
                        <div class="circle"></div>
                        <div class="circle"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

              <!-- Sample Questions Section -->
              <div class="sample-questions-container" @click="toggleQuestions" id="prompts">
              <button class="sample-questions-btn mb-3">
                <span class="dot"></span>
                Sample questions
                <span class="material-symbols-rounded">
                  {{ showQuestions ? 'keyboard_arrow_down' : 'keyboard_arrow_up' }}
                </span>
              </button>
            </div>
            <b-tooltip
              target="prompts"
              triggers="click"
              placement="top"
              custom-class="DROPDOWN-MENU"
            >
              <div>
                <button
                  v-for="(question, index) in default_questions.slice(0, 2)"
                  :key="index"
                  @click="chooseMessage(question, index)"
                  :disabled="loadAnswer || question.displayed"
                  class="prompt_css"
                >
                  {{ question.text }}
                </button>
              </div>
            </b-tooltip>

            <!-- Input Container -->
            <div class="input-container">
              <input
                type="text"
                v-show="NewText.length > 0"
                @click="sendNewMessage"
                :disabled="loadAnswer"
                placeholder="Ask LawBot"
                class="input-field"
                v-model="NewText"
                @keyup.enter="sendNewMessage"
              />
              <span class="material-symbols-rounded nr_send" @click="sendNewMessage">send</span>
            </div>

          </div>

        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import fileImg from "../../assets/copilot/file.png";
import chat from "../../assets/copilot/chat.png";
import star from "../../assets/copilot/Vector.svg";
import CaseSummarizer from "@/store/CaseSummarizer.js";
import Auth from "@/store/Auth.js";
export default {
    props: {
    id: {
        type: Number,
        required: true,
    },
    },
  components: {
  },
  data() {
    return {
      isSidebarVisible: false,
      fileImg,chat,star,
      expertmode:false,
      showModal: false,
      files: [],        
      trial_file: [],
      NewText: "",
      loadAnswer: false,
      chatMessages: [],
      showQuestions: false,
      history:[],
      default_questions: [
        { text: "What is the ratio decidendi of the case?", displayed: false },
        {
          text: "What are the relevant obiter dicta of the case?",
          displayed: false,
        },
        {
          text: "What is the key legal principle or rule that the court relied on in reaching its decision?",
          displayed: false,
        },
        {
          text: "Can you identify the specific reasoning provided by the court that led to the judgment in this case?",
          displayed: false,
        },
        {
          text: "How does the court's decision in this case impact future cases and legal interpretations?",
          displayed: false,
        },
        {
          text: "What factors influenced the court's interpretation of the law and application to the facts of this case?",
          displayed: false,
        },
        {
          text: "Are there any dissenting opinions or conflicting views among the judges that need to be considered?",
          displayed: false,
        },
        {
          text: "What implications does this judgment have for similar cases or legal issues in the future?",
          displayed: false,
        },
      ],
      options: [
        {
          icon: "folder_open",
          label: "View Files",
        },
        {
          icon: "archive",
          label: "Archive",
        },
        {
          icon: "delete",
          label: "Delete",
        },
      ],
    };
  },
   created() {
  CaseSummarizer.OpenSummary(this.id)
    .then((response) => {
      const DATA = response.data.data;
      const OutMessageTemplate = {
        class_1: "chat__outgoing",
        class_2: "chat__bubble__wrapper-outgoing",
         class_3: "chat__bubble-outgoing animated-background-outgoing",
        new: false,
      };
      const InMessageTemplate = {
        class_1: "chat__incoming",
        class_2: "chat__bubble__wrapper-incoming",
        class_3: "chat__bubble-incoming animated-background-incoming",
        new: false,
      };
      
      // LawBot Code
      DATA.questions.forEach((element) => {
        if ((element.answer?.length ?? false) !== 0) {
          // Find index of the question and remove it from default_questions
          const index = this.default_questions.findIndex(
            (x) => x === element.question
          );
          if (index !== -1) {
            this.default_questions.splice(index, 1);
          }

          // Create new instances of OutMessage for each loop iteration
          const OutMessage = { ...OutMessageTemplate, text: element.question };
          this.chatMessages.push(OutMessage);

          try {
            JSON.parse(element.answer).forEach((answer) => {
              const InMessage = { ...InMessageTemplate, text: answer };
              this.chatMessages.push(InMessage);
            });
          } catch (error) {
            const InMessage = { ...InMessageTemplate, text: element.answer };
            this.chatMessages.push(InMessage);
          }
        }
      });

      if (
        DATA.questions.length !== 0 &&
        DATA.questions[DATA.questions.length - 1].answer === null
      ) {
        this.loadAnswer = true;
        this.calltime("none");

        const OutMessage = {
          ...OutMessageTemplate,
          text: DATA.questions[DATA.questions.length - 1].question,
        };
        this.chatMessages.push(OutMessage);
      }
    })
    .catch((error) => {
      console.log(error);
    });
},

  computed: {
    filteredMessages() {
      return this.chatMessages.filter((_, idx) => idx % 2 === 0);
    }
  },
  methods: {
    toggleSidebar() {
      this.isSidebarVisible = !this.isSidebarVisible;
    },
    expertMode(){
      this.expertmode=true;
    },
    toggleQuestions() {
      this.showQuestions = !this.showQuestions; // Toggle the display of questions
    },
     handleFileUpload(event) {
      this.uploadedFiles = Array.from(event.target.files || []);
      this.uploadDocuments();
    },
    handleFileOutput(event) {
      this.uploadedFiles = Array.from(event.target.files || []);
      this.uploadDocuments();
    },
    async uploadDocuments() {
      this.isUploading = true;
      if (!this.uploadedFiles || this.uploadedFiles.length === 0) {
        this.toastError("empty");
        this.isUploading = false; 
        return;
      }

      const formData = new FormData();
      this.uploadedFiles.forEach((file, index) => {
        formData.append(`question_doc[${index}]`, file);
      });
      try {
        const response = await CaseSummarizer.uploadDocument(this.id, formData);
        if (response) {
          this.uploadDoc = true;
          this.toastError("File Upload Successfully");
          this.$refs.PreEventFile.value = ""; 
          if (this.interval) {
            clearInterval(this.interval);
          }
          this.interval = setInterval(async () => {
            try {
              const res = await CaseSummarizer.OpenSummary(this.id);
              const DATA = res.data.data;
              this.uploadedDocuments = DATA.questionDocuments;
              this.uploadDoc = false;
            } catch (error) {
              console.error("Error fetching updated documents:", error);
            }
          }, 10000);
        }
      } catch (error) {
        this.toastError("Error uploading documents.");
        console.error('Error uploading documents:', error);
      } finally {
        this.isUploading = false;
      }
    },
    isTableFormat(text) {
      try {
        const parsedText = JSON.parse(text);
        return Object.prototype.hasOwnProperty.call(parsedText, 'table');
      } catch (error) {
        return false;
      }
      },
      parseTableData(text) {
        try {
          const parsedText = JSON.parse(text);
          return parsedText.table;
        } catch (error) {
          console.error("Failed to parse table data:", error);
          return [];
        }
      },
      getTableHeaders(text) {
        try {
          const parsedText = JSON.parse(text);
          const table = parsedText.table;
          if (table && table.length > 0) {
            // Return the keys of the first row as headers
            return Object.keys(table[0]);
          }
          return [];
        } catch (error) {
          console.error("Failed to get table headers:", error);
          return [];
        }
      },
      formatText(text) {
        const stringPattern = /^\{"string":\s*"(.*)"\}$/;
        const match = text.match(stringPattern);

        if (match) {
          text = match[1];
        }
        return text
          .replace(/\\n*/g, '<br>')
          .replace(/\\n\\n*/g, '<br>')
          .replace(/\\n\\n/g, '<br>')
          .replace(/\\n/g, '<br>')
          .replace(/\n/g, '<br>')
          .replace(/\n\n*/g, '<br>')
          .replace('*', '')
          .replace(/\\/g, '<br>');
      },
    deleteMessage(idx) {
      this.filteredMessages.splice(idx, 1);
      this.$bvModal.hide(`alert-modal-${idx}`);
    },
    truncateText(text, maxLength) {
      if (text.length > maxLength) {
        return text.substring(0, maxLength) + '...';
      }
      return text;
    },
    chooseMessage(message, index) {
      this.loadAnswer = true;
      this.chatMessages.push({
        text: message.text,
        class_1: "chat__outgoing",
        class_2: "chat__bubble__wrapper-outgoing",
        class_3: "chat__bubble-outgoing animated-background-outgoing",
        new: false,
      });

      // Update the displayed property of the chosen question
      this.default_questions[index].displayed = true;
      // // Find the next question that hasn't been displayed
      // const nextQuestion = this.default_questions
      //   .find((question) => !question.displayed);

      var obj = {
        question: message.text,
        mask: "false",
      };
      CaseSummarizer.sendQuestion("campaign", this.id, obj)
        .then(() => {
          this.default_questions.splice(
            this.default_questions.findIndex((x) => x.text === message.text),
            1
          );
          this.calltime("default");
        })
        .catch((error) => {
          console.log(error);
          this.loadAnswer = false;
        });

      var div = document.getElementById("messages");
      div.scrollTop = div.scrollHeight;

      this.$nextTick(() => {
        var messageDisplay = this.$refs.messages1;
        messageDisplay.scrollTop = messageDisplay.scrollHeight;
      });
    },

    sendNewMessage() {
      if (this.NewText.length != 0) {
        this.loadAnswer = true;
        this.chatMessages.push({
          text: this.NewText,
          class_1: "chat__outgoing",
          class_2: "chat__bubble__wrapper-outgoing",
          class_3: "chat__bubble-outgoing animated-background-outgoing",
          new: false,
        });

        var obj = {
          question: this.NewText,
          mask: "false",
        };
        this.NewText = "";

        console.log("Passed into API", obj);

        CaseSummarizer.sendQuestion("statement", this.id, obj)
          .then(() => {
            Auth.getMe().then((res) => {
              localStorage.setItem("userInfo", JSON.stringify(res.data.data));
              const credits = res.data.data.nexlaw_credits;
              this.contract_questions_full = credits.full.contract_questions;
              this.contract_questions_remain =
                credits.full.contract_questions -
                credits.used.contract_questions;
            });
            this.calltime("none");
          })
          .catch((error) => {
            console.log(error);
            this.loadAnswer = false;
            this.loadAnswer = false;
            console.log(error);
            this.loadAnswer = false;
            console.log(error);
          });

        var div = document.getElementById("messages");
        div.scrollTop = div.scrollHeight;

        this.$nextTick(() => {
          var messageDisplay = this.$refs.messages1;
          messageDisplay.scrollTop = messageDisplay.scrollHeight;
        });
      }
    },

    calltime(data, index) {
      var me = this;
      setTimeout(function () {
        if (!me.id) return;

        CaseSummarizer.OpenSummary(me.id)
          .then((response) => {
            const questions = response.data.data.questions;
            const last_q = questions[questions.length - 1];
            if (last_q.answer != null) {
              try {
                JSON.parse(last_q.answer).forEach((answer) => {
                  me.chatMessages.push({
                    text: answer,
                    class_1: "chat__incoming",
                    class_2: "chat__bubble__wrapper-incoming",
                    class_3: "chat__bubble-incoming animated-background-incoming",
                    new: true,
                  });
                });
              } catch (error) {
                me.chatMessages.push({
                  text: last_q.answer,
                  class_1: "chat__incoming",
                  class_2: "chat__bubble__wrapper-incoming",
                  class_3: "chat__bubble-incoming animated-background-incoming",
                  new: true,
                });
              }
              me.loadAnswer = false;

              if (data == "default") {
                me.default_questions.splice(index, 1);
              }
            } else {
              me.calltime(data, index);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }, 5000);
    },


    scrollToBottom() {
      this.$nextTick(() => {
        const chatContainer = this.$refs.messages1;
        chatContainer.scrollTop = chatContainer.scrollHeight;
      });
    },
  }
}
</script>
<style scoped>
/* Sidebar Lawbot */
.sidebar {
  top: 10%;
  position: fixed;
  width: 48px;
  height: 100vh;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0 0 5px #AAABAF;
  right: 0;
}
.top-icon {
  width: 48px;
  height: 64px;
  background-color: var(--primary);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFFFFF;
}

.collapse-btn {
  background-color: #FFFFFF;
  border-radius: 50% !important;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translateX(-50%); 
  bottom: 50%;
  box-shadow: 0 0 5px #AAABAF;
  cursor: pointer;
  span{
    color: var(--primary);
  }
}
.collapse-btns {
  z-index:100;
  background-color: #FFFFFF;
  border-radius: 50% !important;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transform: translateX(-50%); 
  bottom: 35%;
  box-shadow: 0 0 5px #AAABAF;
  cursor: pointer;
  span{
    color: var(--primary);
  }
}

.normal {
  top: 10%;
  width: 1080px;
  position: absolute;
  right: 0;
  background-color: #fff;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.slide-enter-active, .slide-leave-active {
  transition: transform 0.3s ease;
}

.slide-enter, .slide-leave-to /* .slide-leave-active in <2.1.8 */ {
  transform: translateX(100%);
}
.top_bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  background-color:var(--primary)
}

.lb_left {
  color: #FFFFFF;
  display: flex;
  align-items: center;
  gap: 13px;
}

.lb_left span {
  font-size: 24px;
  display: flex;
  align-items: center;
}

.lb_left p {
  margin: 0;
  font-size: 20px;
  font-weight: 600;
  line-height: 1;
  display: flex;
  align-items: center;
  color: #FFFFFF !important;
}

.mode {
  width: 100%;
  display: flex;
  align-items: center;
  background-color: transparent; /* Remove button background */
  border: none; /* Remove button border */
  cursor: pointer;
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 20px !important;
  background: #FFFFFF;
  cursor: pointer;
}

.mode .em {
  margin: 0; 
  font-size: 14px;
  line-height: 1;
  display: flex;
  align-items: center;
  font-weight: 400;
  color: var(--primary) !important;
}

.mode span {
  font-size: 24px;
  display: flex;
  align-items: center;
  margin-left: 5px;
  color: var(--status-blue)  !important;
}


/* Main Content Styles */
.main-content {
  display: flex;
  flex-grow: 1;
}

/* Sidebar Styles */
.left_side {
  width: 33%;
   height: calc(100vh - 64px);
  background-color: #FFFFFF;
  padding: 20px;
  border-right: 1px solid #ddd;
  position: relative;
  overflow-y: auto; 
}

.recent-conversations {
  margin-top: 10px;
  padding: 10px;
}

.message-item {
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  position: relative;
  background-color: var(--light-grey);
}

.message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.message-header p {
  margin: 0;
  font-weight: 600;
}

.message-subheader {
  font-size: 12px;
  color: var(--dark-grey) !important;
  margin-top: 5px;
}

.delete-icon {
  color: var(--dark-grey);
  cursor: pointer;
  &:hover{
    color: var(--status-red);
  }
}

.ls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid var(--mid-grey);
}

.ls p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--primary) !important;
  margin: 0;
}

.ls span {
  color: var(--primary) !important;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.chat-placeholder {
  padding-top: 40%;
  text-align: center;
  p{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
  }
}

.chat-placeholder i {
  font-size: 48px;
  color: #ddd;
  margin-bottom: 10px;
}

/* Right Content Area Styles */
.content-area {
  width: 67%;
  position: relative;
  flex-grow: 1;
  text-align: center;
  height: calc(100vh - 64px);
  overflow-y: auto;
  padding-bottom: 160px;
}
.no-questions {
  height: 100%;
  display: flex; /* Enable Flexbox */
  flex-direction: column; /* Stack child elements vertically */
  align-items: center; /* Center child elements horizontally */
  justify-content: center;
  text-align: center;
}

.no-questions p {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
}
/* Fixed position for sample questions and input */
.sample-questions-container {
  position: fixed;
  bottom: 70px; 
  z-index: 1000;
  margin-left:15px;
}

.input-container {
  height: 80px;
  position: fixed;
  bottom: 0;
  right: 0; /* Align the container to the right edge */
  width: 53%;
  padding: 12px 20px;
  background: var(--interface-grey);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  box-sizing: border-box; /* Ensure padding is included in the width calculation */
}

.input-field {
  flex: 1;
  background: none;
  display: block !important;
}

.nr_send{
  color:var(--primary);
}

.chat-section {
  margin-bottom: 100px; /* Add margin to accommodate the fixed input section */
  max-height: calc(100vh - 200px); /* Adjust based on the fixed elements */
  overflow-y: auto;
}

.chat__box {
  padding-bottom: 20px; /* For better spacing inside the chat box */
}

.sample-questions-btn {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-radius: 20px !important;
  height:40px;
  background-color: #FFFFFF;
  cursor: pointer;
  box-shadow: 0px 4px 10px 0px #E3E5EB99;
  border:none;
}

.dot {
  height: 8px;
  width: 8px;
  background-color: #52C41A;
  border-radius: 50%;
  margin-right: 10px;
}

.questions-list {
  margin-top: 10px;
  padding: 10px;
  border-radius: 6px;
}

.question-btn {
  display: block;
  text-align: left;
  border: 1px solid var(--mid-grey);
  padding: 10px;
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  color: var(--dark-grey);
}
.prompt_css{
  display: block;
  text-align: left;
  padding: 10px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;
  color: var(--neutral-black);
}

</style>
<style scoped>
.custom-header {
  width: 100%;
  height: 60px;
  padding: 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  P{
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
    color:var(--neutral-black);
  }
}

.close-btn {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.custom-body {
  display: flex;
  width: 100%;
  height: auto; 
  padding: 12px 24px; /* Body padding */
  gap: 16px; /* Space between child elements */
}
/* Plan Card Styles */
.plan {
  width: 100%;
  height: auto;
  padding: 16px 0px 0px;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid var(--light-grey);
  background: var(--interface-grey);
    p{
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  }
}
.current{
  height:44px;
  padding:10px;
  gap: 10px;
  border: 1px solid var(--mid-grey);
  border-radius: 25px;
  text-align: center;
  margin: auto 13px;
  p{
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color:var(--dark-grey) !important;
  }
  }
  .update{
  height:44px;
  padding:10px;
  gap: 10px;
  background: #FAE8FF;
  border: 1px solid #C319FF;
  color: #C319FF !important;
  border-radius: 25px;
  text-align: center;
  margin: auto 13px;
  p{
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color:#C319FF !important;
  }
  }
  .list{
    display: flex;
    align-items: center;
    gap: 8px;
    padding-left: 15px;
    p{
    font-size: 16px;
    font-weight: 500;
    line-height:33px;
    color:var(--neutral-black) !important;
    margin: 0;
    }
  }
  .footer{
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    text-align: center !important;
  }
</style>
<style scoped>
.chat-container {
    padding: 20px;
    position: relative;
}
.chat-header {
    margin-bottom: 10px;
}
.header-input {
    width: 100%;
    padding: 8px;
    font-size: 14px;
    border-radius: 8px;
    border: 1px solid #ccc;
}

.chat-box {
    flex: 1;
    overflow-y: auto;
    margin-bottom: 10px;
}

.user-message, .bot-message {
    margin: 10px 0;
}

.user-message {
    background-color: var(--status-blue);
    padding: 10px;
    border-radius: 10px;
    text-align: right;
    margin-left: auto;
    max-width: 40%;
    height:auto;
}
.user-message::before{
  content: "";
  position: absolute;
  top: -10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: #4caf50;
  clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
}
.bot-message-container {
    display: flex;
    align-items: center; /* Aligns icon and text vertically in the center */
    margin: 10px 10px;
    span{
      width: 32px;
      height:32px;
      background:var(--primary);
      border-radius:50%;
      color:#fff;
      display: flex; /* This centers the content within the span */
    justify-content: center; /* Horizontally centers the icon */
    align-items: center;
    }
}
.bot-message {
    background-color: #f0f0f0;
    color: #333;
    padding: 10px;
    border-radius: 10px;
    max-width: 80%;
}

.bot-text {
    font-size: 14px;
    line-height: 1.5;
    text-align: left;
}


.thinking {
    text-align: center;
    font-style: italic;
}

.loading-dots::after {
    content: '...';
    animation: dots 1s steps(5, end) infinite;
}

@keyframes dots {
    0%, 20% {
        content: '.';
    }
    40% {
        content: '..';
    }
    60%, 100% {
        content: '...';
    }
}

.chat-input-container {
    display: flex;
    align-items: center;
}

.chat-input {
    flex: 1;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #ccc;
    font-size: 14px;
    margin-right: 10px;
}

.send-btn {
    background-color: #0084ff;
    color: white;
    border: none;
    border-radius: 50%;
    padding: 10px;
    cursor: pointer;
    font-size: 18px;
}

.sample-questions {
    display: flex;
    justify-content: center;
    margin-top: 10px;
}

.sample-btn {
    background-color: #f0f0f0;
    border: none;
    padding: 8px 16px;
    border-radius: 20px;
    cursor: pointer;
    font-size: 12px;
}
</style>
<style scoped>
.animated-background-outgoing {
  background:var(--status-blue);
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.animated-background-incoming {
  background:var(--light-grey);
  background-size: 200% 100%;
  animation: gradientAnimation 3s linear infinite;
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 0%;
  }

  25% {
    background-position: 100% 0%;
  }

  50% {
    background-position: 100% 100%;
  }

  75% {
    background-position: 0% 100%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.card_question {
  height: 586px;
}

.chat__box {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  height: 100%;
  overflow-y: scroll;
}

.chat__incoming {
  margin-right: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-incoming {
  margin-right: auto;
  margin-bottom: 1rem;
}

.chat__bubble-incoming {
  padding: 0.3rem 1rem;
  background-color: #f1f1f1;
  border-bottom-left-radius: 0px;
  margin-right: 3rem;
  margin-left: 0.5rem;
}

.chat__bubble-incoming span {
  color: #333333;
}

.chat__outgoing {
  margin-left: auto;
  margin-top: 0.5rem;
}

.chat__bubble__wrapper-outgoing {
  margin-left: auto;
  margin-bottom: 1rem;
}

.chat__bubble-outgoing {
  padding: 0.3rem 1rem;
  background-color: var(--status-blue);
  border-bottom-right-radius: 0px;
  margin-left: 3rem;
}

.chat__bubble-outgoing span {
  color: #fff;
}

/* chat suggestions and input */
.chat__suggestion {
  display: flex;
  border: 2px solid #dddddd;
  border-radius: 20px;
  padding-left: 10px;
  height: auto;
  margin-bottom: 0.5rem;
}

.chat__suggestion:hover {
  background-color: #f1f1f1;
  transition: 300ms ease-out;
}

.chat__suggestion span {
  text-align: left;
  padding-top: 0.2rem;
}

.chat__input {
  display: flex;
  border: 2px solid #0058a2;
  border-radius: 20px;
  padding-left: 10px;
}

.chat__input input {
  border: none;
  border-radius: 20px;
  height: 40px;
  width: 100%;
  box-shadow: none;
  outline: none;
}

.chat__input .btn-send {
  padding: 0;
  margin: 0;
  height: 38px;
}

.chat__input .btn-send span {
  color: #0058a2;
  font-size: 40px;
}

.chat__input .btn-send:hover span {
  color: #004278;
  transition: 300ms ease-out;
}

.accordion-checkbox {
  display: none;
}

.accordion-label {
  cursor: pointer;
  display: block;
}

.accordion-icon {
  transition: transform 0.3s;
}

.accordion-checkbox:checked ~ .accordion-label .accordion-icon {
  transform: rotate(-180deg);
}

.card-header {
  padding: 0rem 0rem !important;
  padding-top: 1rem !important;
  margin-bottom: 0 !important;
  color: var(--bs-card-cap-color) !important;
  background-color: #ffffff !important;
  border-bottom: 0px solid #ffffff !important;
}

.content-card {
  height: 650px;
}

.underlined {
  border-bottom: 1px solid lightgray;
}

table tbody {
  height: 410px;
  overflow-y: scroll;
}
</style>
<style scoped>
.dot_active{
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: var(--status-green);
  margin-right: 5px;
}
.pur{
  color: var(--status-violet);
  width: 20px;
  height: 16px;
  cursor:pointer;
}
.blu{
  color: var(--status-violet);
}
.st_icon {
  background: var(--primary); /* Use your primary color */
  border-radius: 50%; /* Make the icon circular */
  width: 35px; /* Set fixed width */
  height: 32px; /* Set fixed height */
  color: #FFFFFF; /* Make the icon color white */
  display: flex; /* Align the icon within the circle */
  justify-content: center; /* Center the icon horizontally */
  align-items: center; /* Center the icon vertically */
  font-size: 24px; /* Adjust the icon size as needed */
}

</style>

<style scoped>
.delete-prompt {
  margin: 8px 8px 20px 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.delete-prompt p {
  margin-bottom: 0px;
  text-align: center;
}

.delete-prompt p b {
  font-weight: 500;
  font-size: 16px;
  color: var(--neutral-black);
}

.delete-prompt-options {
  display: flex;
  justify-content: space-between;
  margin: 0px 8px 8px 8px;
}

.delete-prompt-options button {
  width: calc(50% - 4px);
  height: 32px;
}
.trial{
  background: var(--primary);
  color: #FFFFFF;
}
.DROPDOWN-MENU ::v-deep .tooltip-inner {
  max-width: 261px !important;
  height: 212px !important;
  overflow-y: auto;
  padding:10px;
  border:1px solid var(--light-grey);
  box-shadow: 0px 4px 10px 0px #AAABAF99;

}
</style>
