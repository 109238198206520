<template>
  <div class="page-content">
    <div style="background-color: white; padding-top: 16px; padding-left: 16px;">
      <BreadCrumb title="Case Law Summarizer" :subtitle="file_name"></BreadCrumb>
    </div>
    <header class="tabs-header">
      <nav class="tabs">
        <button class="tab" :class="{ active: activeTab === 'summary' }" @click="setActiveTab('summary')"
          tabindex="0">Case Summary</button>
        <button class="tab" :class="{ active: activeTab === 'complete' }" @click="setActiveTab('complete')"
          tabindex="0">Complete Case</button>
        <button class="tab" :class="{ active: activeTab === 'precedents' }" @click="setActiveTab('precedents')"
          tabindex="0">Precedents</button>
      </nav>
    </header>
    <div class="main-summary-container">
      <div class="main-summary-left">
        <div>
          <div @click="hideAllDropdown">

            <div v-if="activeTab === 'summary'">
              <div class="main-container d-flex mt-5" v-if="pageLoading">
                <PageLoader />
              </div>
              <div class="main-container d-flex" v-else>
                <div class="memo-container" :class="{ 'expanded': isExpanded }">
                  <div class="container mt-5" style="max-width:900px;">
                    <div class="card">
                      <article class="legal-memo">
                        <header class="memo-header">
                          <h3 class="memo-title">Case Summary Result</h3>
                          <div class="action-buttons">
                            <button class="export-button" @click="toggleDropdown">
                              <span class="export-button__text">Export</span>
                              <img
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/73101f93fa6e10686834388f299ddb0407f1cd8a9c1f8f478b982ef1d490b350?placeholderIfAbsent=true&apiKey=072fca6f880e47769db5f7634dd8a8c6"
                                alt="" class="export-button__icon" />
                            </button>
                            <div v-if="showDropdown" class="dropdown-modal">
                              <div class="dropdown-option" v-for="(export_item, export_item_normal) in exports_items"
                                :key="'export_pdf' + export_item_normal">
                                <input :name="'pdf' + export_item_normal" :id="'pdf' + export_item_normal"
                                  type="checkbox" :value="export_item" v-model="pdf_status" class="checkbox">
                                <label class="option-text">{{ getExportItemName(export_item.name) }}</label>
                              </div>
                              <button class="export-button-modal" aria-label="Export" @click="exportData">
                                <img loading="lazy"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/a3be09bad3700d9f41e45db3cdacab82f1e6f0745db3f2020a449eddf61e0a0a?placeholderIfAbsent=true&apiKey=072fca6f880e47769db5f7634dd8a8c6"
                                  alt="" class="export-icon" />
                                <span class="export-text">Export</span>
                              </button>
                            </div>

                          </div>
                        </header>
                        <span class="line"></span>
                        <main class="memo-content">
                          <section class="memo-section">
                            <h4 class="section-title" style="margin-bottom: 40px;">Case Info</h4>
                            <h5 class="section-subtitle">Case Title</h5>
                            <h6 class="section-content">
                              {{ file_name }}
                            </h6>
                          </section>
                          <section class="memo-section" v-if="topic">
                            <h5 class="section-subtitle">Topic</h5>
                            <h6 class="section-content">
                              {{ topic }}
                            </h6>
                          </section>
                          <section class="memo-section" v-if="abstract">
                            <h5 class="section-subtitle">Abstract</h5>
                            <h6 class="section-content">
                              {{ abstract }}
                            </h6>
                          </section>
                          <div class="case-info__meta">
                            <div class="case-info__date" v-if="date">
                              <time datetime="2016-10-25">
                                <span class="case-info__day">{{ date }}</span>
                                <span class="case-info__month">{{ date }}</span>
                              </time>
                              <span class="case-info__label">Decision Date</span>
                            </div>
                            <div class="case-info__details">
                              <div class="case-info__jurisdiction" v-if="country">
                                <h6 class="case-info__label">Jurisdiction</h6>
                                <p class="case-info__value">{{ country }}</p>
                              </div>
                              <div class="case-info__court" v-if="court">
                                <h6 class="case-info__label">Court Name</h6>
                                <p class="case-info__value">{{ court }}</p>
                              </div>
                            </div>
                          </div>

                          <span class="line"></span>

                          <section class="memo-section">
                            <div class="summary-title">
                              <h4 class="section-title" style="margin-bottom: 40px;">Summary</h4>
                              <button class="btn btn-light" v-b-tooltip.hover.top="{ variant: 'secondary' }"
                                title="Copy" @click="copyContent">
                                <img src="../../assets/icon/copy.svg" alt="" class="btn-icon" />
                                Copy
                              </button>
                            </div>
                            <div class="my-1" style="display: flex;">
                              <h5 class="section-subtitle">{{ "Brief Summary" }}</h5>
                              <img
                                src="https://cdn.builder.io/api/v1/image/assets/TEMP/be98e6e7c327af7d118fe81247fe84cb38848926818d2b97945aad22d1b8cb25?placeholderIfAbsent=true&apiKey=072fca6f880e47769db5f7634dd8a8c6"
                                @click="toggleSpeech(content_original)" :title="isSpeaking ? 'Pause' : 'Audio Summary'"
                                alt="Audio Summary Icon"
                                style="width: 24px; height: 24px; margin-left: 10px; cursor: pointer;"
                                id="tooltip-target-1" />
                              <b-tooltip target="tooltip-target-1" triggers="hover">
                                {{ isSpeaking ? "Pause" : "Audio" }}
                              </b-tooltip>
                            </div>
                            <p v-if="checked" id="ner_content" class="section-content" v-html="content"></p>
                            <p v-else class="ner_content">{{ content_original }}</p>

                            <h5 class="section-subtitle" style="margin-top: 20px;">Key Points</h5>

                            <ul class="key-points-list">
                              <li v-for="(key_point, index) in key_points_original" :key="'key_points' + index"
                                class="key-point-item">
                                <span class="key-point-index" style="  margin-right: 4px;">{{ index + 1 }}.</span>
                                <div class="key-point-content">
                                  <p v-if="checked" class="key-point-highlight" id="ner_content"
                                    v-html="key_points[index]"></p>
                                  <p v-else class="key-point-highlight">{{ key_point }}</p>
                                </div>
                                <img
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/be98e6e7c327af7d118fe81247fe84cb38848926818d2b97945aad22d1b8cb25?placeholderIfAbsent=true&apiKey=072fca6f880e47769db5f7634dd8a8c6"
                                  @click="toggleSpeechKeyPoints(key_point, index)"
                                  :title="isSpeaking ? 'Pause' : 'Audio Summary'" alt="Audio Summary Icon"
                                  style="width: 24px; height: 24px; cursor: pointer;" id="tooltip-target-2" />
                              </li>
                            </ul>

                          </section>
                        </main>
                      </article>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div v-if="activeTab === 'complete'">
              <div class="main-container d-flex mt-5" v-if="pageLoading">
                <PageLoader />
              </div>
              <div class="main-container d-flex" v-else>
                <div class="container mt-5" style="max-width:900px;">
                  <article class="legal-memo">
                    <h3 class="memo-title">Full Case</h3>
                    <span class="line"></span>
                    <iframe class="case-image-secondary" :src="case_file" frameborder="0" height="600px"
                      width="100%"></iframe>
                    <p class="case-copyright-notice">
                      NexLaw does not hold any copyright for the case content. All content displayed remains the
                      property of its original source.
                    </p>
                  </article>
                </div>
              </div>
            </div>

            <div v-if="activeTab === 'precedents'">
              <div class="main-container d-flex mt-5" v-if="pageLoading">
                <PageLoader />
              </div>
              <div class="main-container d-flex" v-else>
                <div class="container mt-5">
                  <section class="precedent-list">
                    <header class="precedent-header">
                      <h3 class="memo-title">Precedents</h3>
                      <span class="line"></span>
                    </header>
                    <div v-if="precedents.length === 0">
                      <div class="empty-placeholder">
                        <span class="min-margin"></span>
                        <img src="@/assets/misc/empty.svg" alt="No Content" />
                        <p>No Precedents Found <br>
                          We couldn’t generate any precedents from the uploaded file. Try <br>
                          uploading a different document to summarize.
                        </p>
                      </div>
                    </div>
                    <div v-else>
                      <CaseList caseType="Precedents" :filtered_searched_data="mappedPrecedents"></CaseList>
                    </div>

                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="main-summary-right">
        <LawBot :id="Number(id)" />
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from '../../components/misc/BreadCrumb.vue';
import CaseSummarizer from "@/store/CaseSummarizer.js";
import { ExportData } from "../../store/utils";
import PageLoader from './Loader.vue';
import LawBot from './LawBot.vue';
import CaseList from '../LegalResearch/CaseList.vue';

export default {
  name: 'CaseSummary',
  components: {
    PageLoader,
    BreadCrumb,
    LawBot,
    CaseList
  },
  data() {
    return {
      id: this.$route.params.id,
      showDropdown: false,
      activeTab: 'summary',
      isExpanded: false,
      file_name: "",
      topic: "",
      abstract: "",
      date: "",
      court: "",
      country: "",
      collapseStates: [],
      innerCollapseStates: [],
      permissions: localStorage.permissions,
      NewText: "",
      loadAnswer: false,
      selectedTab: "caseDocument",
      pageLoading: true,
      case_file: "",
      content_to_copy: "",
      content_to_export: "",
      content_to_export_without_ner: "",
      precedents: [],
      content: "",
      exports_items: [
        { name: "PDF", icon: "picture_as_pdf" },
        { name: "DOCUMENT", icon: "description" },
      ],
      status: false,
      pdf_status: [],
      checked: false,
      ner_list: [],
      entities: [],
      chosenPage: "",
      pages: [],
      languages: [
        "English",
        "Bahasa Malaysia",
        "Filiphino",
        "Thai",
        "Tamil",
        "Chinese",
      ],
      chosenLanguage: "",
      key_points: [],
      key_points_original: [],
      isSpeaking: false,
      utterance: null,
      buttonStates: [],
      colors: {
        LAWYER: "#7EDFFF",
        COURT: "#9ACD32",
        JUDGE: "#C6BFFF",
        PETITIONER: "#FF7E7E",
        RESPONDENT: "#FFA500",
        CASE_NUMBER: "#BCC2D8",
        GPE: "#87CEFA",
        DATE: "#C1FFC1",
        ORG: "#FFDD7F",
        STATUTE: "#F5DEB3",
        WITNESS: "#FFFF8F",
        PRECEDENT: "#CBE3E2",
        PROVISION: "#F4A460",
        OTHER_PERSON: "#CBCBCB",
      },
      full_form: {
        LAWYER: "lawyer",
        COURT: "court",
        JUDGE: "judge",
        PETITIONER: "petitioner",
        RESPONDENT: "respondent",
        CASE_NUMBER: "Case number",
        GPE: "Countries, cities, state",
        DATE: "date",
        ORG: "organisation",
        STATUTE: "statute",
        WITNESS: "witness",
        PRECEDENT: "precedent",
        PROVISION: "provision",
        OTHER_PERSON: "People names",
      },
      content_original: "",
      ner_final: [],
      NerTagExplanation:
        "*NER = Natural Language Processing Technique that identifies and classifies named entities from the case",
      PleaseWaitText:
        "Please wait a moment while we process it. This may take some time depending on the size the file and your internet connection speed.",
    };
  },
  computed: {
    mappedPrecedents() {
      return this.precedents.map((precedent) => ({
        id: precedent.id,
        name: precedent.name,
        court: precedent.court,
        type: precedent.country,
        completedPerCentage: 100,
        date: precedent.judgement_date,
        content: precedent.abstract,
        highlights: precedent.abstract,
        topic: precedent.topic
      }));
    }
  }, 
  mounted() {
    this.collapseStates = this.precedents.map(() => false);
    this.innerCollapseStates = this.precedents.map(() => false);
  },
  methods: {
    getExportItemName(name) {
      return name.toLowerCase() === 'document' ? '.docx' : name;
    },
    toggleCollapse(index, isInner = false) {
      if (isInner) {
        this.$set(this.innerCollapseStates, index, !this.innerCollapseStates[index]);
      } else {
        this.$set(this.collapseStates, index, !this.collapseStates[index]);
      }
    },
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },
    setActiveTab(tab) {
      this.activeTab = tab;
    },
    hideAllDropdown() { this.$root.$emit('bv::hide::tooltip'); },
    goBack() {
      this.$router.push({ name: 'LegalAdvice' });
    },
    toggleWidth() {
      this.isExpanded = !this.isExpanded;
    },
    exportData() {
      if (this.pdf_status.length === 0) {
        this.$toast.error("Kindly select a format to export");
        return;
      }
      var status = ExportData(
        this.status
          ? this.content_to_export
          : this.content_to_export_without_ner,
        [
          this.pdf_status.find((el) => el.name === "DOCUMENT"),
          this.pdf_status.find((el) => el.name === "PDF"),
        ]
      );
      if (status[0]) {
        this.$toast.success("Successfully downloaded Document");
      }
      if (status[1]) {
        this.$toast.success("Successfully downloaded PDF");
      }
      this.pdf_status = [];
    },
    copyContent() {
      navigator.clipboard.writeText(this.content_to_copy);
      this.$toast.success("Copied");
    },
    toggleSpeech(text, index) {
      const target = index == null ? this : this.buttonStates[index];

      if (!target.isSpeaking) {
        if (this.utterance?.text ?? null === text) {
          try {
            speechSynthesis.resume();
            target.isSpeaking = true;
          } catch (error) {
            this.initializeUtterance(text, index);
            speechSynthesis.speak(this.utterance);
          }
        } else {
          speechSynthesis.cancel();
          target.isSpeaking = false;
          this.initializeUtterance(text, index);
          speechSynthesis.speak(this.utterance);
        }
      } else {
        speechSynthesis.pause();
        target.isSpeaking = false;

        this.utterance.onpause = () => {
          target.isSpeaking = false;
          this.utterance.onpause = null;
        };
      }
    },
    initializeUtterance(speechText, index = null) {
      const target = index == null ? this : this.buttonStates[index];

      this.utterance = new SpeechSynthesisUtterance(speechText);
      this.utterance.onstart = () => {
        target.isSpeaking = true;
      };
      this.utterance.onend = () => {
        speechSynthesis.cancel();
        target.isSpeaking = false;
      };
    },
    toggleSpeechKeyPoints(text, index) {
      var speechText = text;
      const currentButtonState = this.buttonStates[index];
      if (!currentButtonState.isSpeaking) {
        if (
          this.utterance &&
          this.utterance.text !== "" &&
          this.utterance.text === speechText
        ) {
          try {
            speechSynthesis.resume();
            currentButtonState.isSpeaking = true;
          } catch (error) {
            this.initializeUtteranceKeyPoints(speechText, index);
            speechSynthesis.speak(this.utterance);
          }
        } else {
          speechSynthesis.cancel();
          currentButtonState.isSpeaking = false;
          this.initializeUtteranceKeyPoints(speechText, index);
          speechSynthesis.speak(this.utterance);
        }
      } else {
        speechSynthesis.pause();
        currentButtonState.isSpeaking = false;

        this.utterance.onpause = () => {
          currentButtonState.isSpeaking = false;
          this.utterance.onpause = null;
        };
      }
    },
    initializeUtteranceKeyPoints(speechText, index) {
      const currentButtonState = this.buttonStates[index];

      this.utterance = new SpeechSynthesisUtterance(speechText);
      this.utterance.onstart = () => {
        currentButtonState.isSpeaking = true;
      };
      this.utterance.onend = () => {
        speechSynthesis.cancel();
        currentButtonState.isSpeaking = false;
      };
    },
  },
  created() {
    CaseSummarizer.OpenSummary(this.$route.params.id)
      .then((response) => {
        const DATA = response.data.data;
        this.pageLoading = false;

        this.case_file = DATA.case_file;
        this.file_name = DATA.file_name;
        this.topic = DATA.topic;
        this.abstract = DATA.abstract;
        this.date = DATA.date;
        this.court = DATA.court;
        this.country = DATA.country;

        // this.precedents = JSON.parse(
        //   DATA.values.find((el) => el.key === "precedents").value
        // );
        this.precedents = JSON.parse(
          DATA.values.find((el) => el.key === "link").value
        );
        this.content_original = JSON.parse(
          DATA.values.find((el) => el.key === "brief_summary").value
        );
        this.content = this.content_original;

        this.key_points_original = JSON.parse(
          DATA.values.find((el) => el.key === "key_points").value
        );

        this.buttonStates = this.key_points_original.map(() => ({
          isSpeaking: false,
        }));

      })
      .catch((error) => {
        console.log(error);
        this.pageLoading = false;
      });
  },
};
</script>

<style scoped>
/* Export Button Style */
.export-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 4px 16px !important;
  border: none;
  border-radius: 4px;
  background-color: var(--primary-blue, #0e4485);
  color: var(--neutral-white, #fff);
  font: 500 16px Poppins, sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  /* Add this line */
}

.export-button-modal {
  position: absolute;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;
  top: 20px;
  left: 115px;
  padding: 2px 12px;
  border: none;
  border-radius: 4px;
  background-color: var(--primary-blue, #0e4485);
  color: var(--neutral-white, #fff);
  font: 500 16px Poppins, sans-serif;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: relative;
  /* Add this line */
}

.export-button-modal:hover {
  background-color: var(--primary-blue-dark, #0a3366);
}


.export-button__text {
  white-space: nowrap;
}

.export-button__icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

/* Dropdown Style */
.dropdown-modal {
  position: absolute;
  /* Change to absolute positioning */
  top: 50px;
  right: 30px;
  margin-top: 10px;
  /* Add some space between the button and the dropdown */
  justify-content: center;
  border-radius: 8px;
  border: 1px solid var(--Neutral-Grey, #f2f3f3);
  background: var(--Neutral-White, #fff);
  box-shadow: 0 4px 10px rgba(170, 171, 175, 0.6);
  display: flex;
  max-width: 239px;
  flex-direction: column;
  color: var(--Neutral-Black, #383a3e);
  white-space: nowrap;
  padding: 10px 130px 35px 0;
  font: 500 14px/1 Poppins, sans-serif;
}

.dropdown-option {
  display: flex;
  width: 200%;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  justify-content: flex-start;
  padding: 12px 16px;
}

.checkbox {
  border-radius: 4px;
  border: 1px solid var(--Neutral-Black, #383a3e);
  align-self: stretch;
  display: flex;
  width: 24px;
  height: 24px;
  margin: auto 0;
  cursor: pointer;
}

.option-text {
  align-self: stretch;
  margin: auto 0;
}

.export-button-wrapper {
  display: flex;
  width: 100%;
  padding-top: 8px;
  align-items: center;
  gap: 10px;
  overflow: hidden;
  justify-content: flex-end;
}

.button-text {
  align-self: stretch;
  margin: auto 0;
  color: var(--Neutral-White, #fff);
}

/* Key Points Style */
.key-points-section {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 24px;
}

.section-title {
  color: #383a3e;
  font: 500 16px/28px Poppins, sans-serif;
  width: 100%;
}

.key-points-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.key-point-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 12px;
  width: 100%;
}

.key-point-content {
  color: #86888d;
  font: 400 14px/24px Poppins, sans-serif;
  flex-grow: 1;
  width: calc(100% - 40px);
}

.key-point-highlight {
  color: #383a3e;
}

.key-subpoint-highlight {
  color: #86888d;
}

.expand-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
}

.expand-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

@media (max-width: 991px) {

  .key-points-section,
  .section-title,
  .key-point-item,
  .key-point-content {
    max-width: 100%;
  }
}

/* Page Contents Style */

.page-content {
  padding: 0;
  /* Remove padding */
}

/* Header */
.tabs-header {
  padding: 0 12px 0 0;
  align-items: center;
  border-bottom: 1px solid #f2f3f3;
  background: #fff;
  display: flex;
  overflow: hidden;
  color: #86888d;
  justify-content: flex-start;
  font: 400 14px/24px Poppins, sans-serif;
}

.tabs {
  align-self: stretch;
  display: flex;
  min-width: 240px;
  justify-content: flex-start;
  margin: auto 0;
}

.tab {
  align-self: stretch;
  padding: 8px 16px;
  background: none;
  border: none;
  cursor: pointer;
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  border-radius: 0px !important;
}

.tab.active {
  border-bottom: 1px solid #0e4485;
  color: #0e4485;
}

@media (max-width: 991px) {
  .tab:last-child {
    white-space: normal;
  }
}

.line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #d1d2d5;
  /* Adjust the color as needed */
  margin: 16px 0;
  /* Adjust the margin as needed */
}


/************************************************************************************************** */

.left {
  color: var(--primary);
  cursor: pointer;
}

.right {
  background: var(--primary);
  color: #fff;
  width: 183px;
  height: 32px;
  padding: 4px 16px;
  gap: 8px;
  border-radius: 6px;

}

.legal-memo {
  max-width: 880px;
  margin: 0 auto;
  padding: 16px 32px 32px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  font-family: Poppins, sans-serif;
}

.memo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 24px;
}

.memo-title {
  font-size: 20px;
  font-weight: 600;
  color: #383a3e;
  margin-top: 10px;
}

/******** Decision Cards ********/
.case-info__meta {
  display: flex;
  gap: 20px;
}

.case-info__date {
  background-color: #fafbfc;
  border-radius: 16px;
  padding: 30px;
  text-align: center;
}

.case-info__day {
  display: block;
  font-size: 32px;
  font-weight: 600;
}

.case-info__month {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
}

.case-info__label {
  color: #86888d;
  font-size: 14px;
  padding: 8px;
}

.case-info__details {
  flex-grow: 1;
}

.case-info__jurisdiction,
.case-info__court {
  background-color: #fafbfc;
  border-radius: 16px;
  padding: 2px;
  margin-bottom: 16px;
}

.case-info__value {
  color: #383a3e;
  font-size: 16px;
  padding-left: 8px;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.btn {
  display: flex;
  align-items: center;
  gap: 4px;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  margin-right: 8px;
  margin-left: auto;
  /* Align to the end */
}

.btn-secondary {
  border: 1px solid #d1d2d5;
  background-color: #fff;
  color: #000046;
}

.btn-primary {
  background-color: #0e4485;
  color: #fff;
  border: none;
}

.btn-icon {
  width: 18px;
  height: 18px;
}

.memo-section {
  margin-bottom: 30px;
}

.summary-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.section-title {
  padding-top: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #383a3e;
}

.section-subtitle {
  font-size: 16px;
  color: #383a3e;
  margin-bottom: 10px;
}

.section-content {
  font-size: 14px;
  color: #86888d;
  line-height: 1.5;
}

.highlighted-section {
  background-color: #f2f3f3;
  border-radius: 16px;
  padding: 8px 16px;
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.expand-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.expand-icon {
  width: 22px;
  height: 22px;
}

.section-divider {
  border: none;
  border-top: 1px solid #d1d2d5;
  margin: 24px 0;
}

.citation-list {
  padding-left: 20px;
  font-size: 14px;
  color: #1890ff;
}

.citation-list a {
  color: inherit;
  text-decoration: underline;
}

@media (max-width: 991px) {
  .legal-memo {
    padding: 16px 20px;
  }

  .memo-header {
    flex-direction: column;
    align-items: flex-start;
  }

  .action-buttons {
    width: 100%;
    justify-content: flex-end;
  }
}

.main-container {
  display: flex;
  justify-content: space-between;
}

.memo-container {
  width: 100%;
  /* Default width */
  transition: width 0.3s ease;
}

.sidebar {
  transition: width 0.3s ease;
}

.memo-container.expanded {
  width: 65%;
  /* New width when expanded */
}

.sidebar.expanded-sidebar {
  display: block;
  width: 35%;
  /* Sidebar takes 35% of the width */
}
</style>

<style scoped>
.empty-placeholder {
  min-height: 40%;
  width: 500px;

  .min-margin {
    display: inline-block;
    height: 50px;
  }

  img {
    margin-top: auto;
    margin-bottom: 24px;
  }

  p {
    text-align: center;
    margin-bottom: 32px;
  }
}

/*********** Collapse Card Styles ***********/
.memo-section {
  margin-bottom: 30px;
}

.section-title {
  font-size: 16px;
  font-weight: 500;
  color: #383a3e;
}

.section-subtitle {
  font-size: 16px;
  color: #383a3e;
  margin-bottom: 10px;
}

.section-content {
  font-size: 14px;
  color: #86888d;
  line-height: 1.5;
}

.case-info__meta {
  display: flex;
  gap: 20px;
}

.case-info__date {
  background-color: #fafbfc;
  border-radius: 16px;
  padding: 30px;
  text-align: center;
}

.case-info__day {
  display: block;
  font-size: 32px;
  font-weight: 600;
}

.case-info__month {
  display: block;
  font-size: 16px;
  margin-bottom: 8px;
}

.case-info__label {
  color: #86888d;
  font-size: 14px;
  padding: 8px;
}

.case-info__details {
  flex-grow: 1;
}

.case-info__jurisdiction,
.case-info__court {
  background-color: #fafbfc;
  border-radius: 16px;
  padding: 2px;
  margin-bottom: 16px;
}

.case-info__value {
  color: #383a3e;
  font-size: 16px;
  padding-left: 8px;
}

/**********************************************************/
.collapse-card {
  width: 680px;
  /* Adjust the width as needed */
  border: 1px solid var(--Status-Blue, #1890FF);
  border-radius: 8px;
  border: 1px solid var(--Status-Blue, #1890FF);
  background: #FFF;
}

.image-container {
  display: flex;
  justify-content: center;
  padding-top: 59px;
}

.title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
}

.content-image {
  aspect-ratio: 1;
  object-fit: contain;
  width: 150px;
  max-width: 100%;
}

.memo-title {
  font-size: 20px;
  font-weight: 600;
  color: #383a3e;
  margin-top: 10px;
}

.line {
  display: block;
  width: 100%;
  height: 1px;
  background-color: #d1d2d5;
  /* Adjust the color as needed */
  margin: 30px 0;
  /* Adjust the margin as needed */
}

.predecent-line {
  display: block;
  width: 90%;
  height: 1px;
  background-color: #d1d2d5;
  /* Adjust the color as needed */
  margin: 30px 0;
  /* Adjust the margin as needed */
}

.precedent-list {
  max-width: 880px;
  margin: 0 auto;
  padding: 16px 32px 32px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 10px rgba(227, 229, 235, 0.6);
  font-family: Poppins, sans-serif;
}

.precedent-header {
  display: flex;
  flex-direction: column;
  color: #383a3e;
  font: 600 24px Poppins, sans-serif;
}

.precedent-title {
  margin: 0;
}

.divider {
  width: 100%;
  margin-top: 16px;
  stroke: #d1d2d5;
}

.precedent-items {
  list-style-type: none;
  padding: 0;
  margin: 24px 0 0;
}

.precedent-item {
  list-style-type: none;
  display: flex;
  align-items: flex-start;
  gap: 16px;
  margin-bottom: 24px;
}

.precedent-number {
  border-radius: 50%;
  background-color: #0e4485;
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  font: 500 14px/1 Poppins, sans-serif;
}

.precedent-card {
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 46px);
  padding: 4px 8px;
}

.precedent-card:hover {
  background-color: #f2f3f3;
  /* Change background color on hover */
}

.precedent-card {
  transition: background-color 0.3s ease;
}

.precedent-card.expanded {
  background-color: #E8F4FF;
}

.precedent-content {
  flex: 1;
  height: auto;
  width: 685px;
  cursor: pointer
}

.precedent-case-title {
  font: 500 16px/28px Poppins, sans-serif;
  color: #383a3e;
  margin: 0;
}

.precedent-description {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--Neutral-Dark-Grey, #86888d) !important;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
}

.expand-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px;
}

.expand-icon {
  width: 24px;
  height: 24px;
}

@media (max-width: 991px) {
  .precedent-list {
    padding: 20px;
  }

  .precedent-header,
  .precedent-card {
    max-width: 100%;
  }
}

.main-summary-container {
  display: flex;
  justify-content: space-between;

  .main-summary-left {
    width: 90%;
  }

  .main-summary-right {
    width: 10%;
  }
}
</style>