import axios from "@/axios.js";

export default {
  /**
   * Retrieve updated userInfo
   * @param {*} me Vue's `this` instance
   */
  GetMe() {
    return new Promise((resolve, reject) => {
      axios
        .get("me")
        .then((response) => {
          if (!response) return;

          const UI = response.data.data;
          if (![undefined, "undefined", null, "null"].includes(UI.nexlaw_credits)) localStorage.setItem("userInfo", JSON.stringify(UI));

          resolve(response);
        })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Retrieve campaign list
   * @param {*} args Queries to filter the list
   */
  GetCampaigns(args = null) {
    var API = `nexlaw`;
    if (args) {
      API += `?` + Object
        .entries(args)
        .map(([k, v]) => `${k}=${v}`)
        .join(`&`);
    }

    return new Promise((resolve, reject) => {
      axios
        .get(API)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Permanently delete a campaign
   * @param {*} id Campaign ID
   */
  DeleteCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`nexlaw/${id}/delete`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Move campaign into `Archived Campaigns`
   * @param {*} id Campaign ID
   */
  ArchiveCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/${id}/archive`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Unarchive campaign
   * @param {*} id Campaign ID
   */
  UnarchiveCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/${id}/unarchive`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

    /**
   * Bookmark campaign
   * @param {*} id Campaign ID
   */
  BookmarkCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/${id}/bookmark`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },
  
  UploadFile(item) {
    console.log(item);
    return new Promise((resolve, reject) => {
      console.log(item);
      axios
        .post(`nexlaw/case-summarizer`, item, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetCaseSummary(obj) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw?type=case_summarizer` + obj)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  OpenSummary(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/${id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendQuestion(type, id, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/case/${type}/${id}/ask`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  /**
   * Move All Selected into `Archived Campaigns`
   * @param {*} id Campaign ID
   */
  ArchiveCampaignAll(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/archive-all`, item)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

    /**
   * Unarchive All Selected campaign
   * @param {*} id Campaign ID
   */
    UnarchiveCampaignAll() {
        return new Promise((resolve, reject) => {
          axios
            .post(`nexlaw/unarchive-all`)
            .then((response) => { resolve(response); })
            .catch((error) => { reject(error); });
        });
      },

    /**
     * Bookmark All Selected campaign
     * @param {*} id Campaign ID
     */
    BookmarkAll(item) {
        return new Promise((resolve, reject) => {
        axios
            .post(`nexlaw/bookmark-all`,item)
            .then((response) => { resolve(response); })
            .catch((error) => { reject(error); });
        });
    },

    /**
     * Unbookmark All Selected campaign
     * @param {*} id Campaign ID
     */
    UnbookmarkAll() {
        return new Promise((resolve, reject) => {
        axios
            .post(`nexlaw/unbookmark-all`)
            .then((response) => { resolve(response); })
            .catch((error) => { reject(error); });
        });
    },

    /**
     * Permanently delete All Selected campaign
     * @param {*} id Campaign ID
     */
    DeleteCampaignAll(item) {
        return new Promise((resolve, reject) => {
          axios
            .delete('nexlaw/delete-all', {
              data: item
            })
            .then((response) => {
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      }
}